import { store, authActions } from '../_store';

export const fetchWrapper = {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE')
};

function request(method) {
    return (url, body) => {
        const requestOptions = {
            method,
            headers: authHeader(url),
				credentials: 'include',
        };
        if (body) {
			const token=localStorage.getItem("token");
 if (token) {
  requestOptions.headers['Authorization'] = token;
 }
            requestOptions.headers['Content-Type'] = 'application/json';
            requestOptions.body = JSON.stringify(body);
			//console.log(requestOptions.body);
        }
        return fetch(url, requestOptions).then(handleResponse);
    }
}

// helper functions

function authHeader(url) {
    // return auth header with jwt if user is logged in and request is to the api url accounts users
	const baseUrl = `${process.env.REACT_APP_API_URL}/`;
    const token = localStorage.getItem("token");//authToken();Bearer 
    const isLoggedIn = token //!!token;
    const isApiUrl = url.startsWith(baseUrl);
    if (isLoggedIn && isApiUrl) {
        return { Authorization: token };
    } else {
        return {};
    }
}


function authToken() {
//    return store.getState().auth.user?.token;
//console.log(store.getState().auth.user?.jwtToken);
return store.getState().auth.user?.jwtToken;
}

function handleResponse(response) {
//	//console.log(response.text);
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if ([401, 403].includes(response.status) && authToken()) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                const logout = () => store.dispatch(authActions.logout());
                logout();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}