import React, { useEffect, useState, useRef } from 'react';

import { Link, useParams } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
//import axios from "axios";
//import http from "../../http-common";
import UploadService from "../../_services/FileUploadService";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import { authActions } from '_store';
import { ProductsService, CategoriesService, alertService } from '../../_services';
import { categoriesActions } from '../../_store';
import AutoSelect from "../../_helpers/autoselect";

import  Select ,  { OptionsType ,  ValueType }  from  "react-select" ;
import { Options, OnChangeValue } from "react-select";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./style.scss";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import slugify from 'react-slugify';
import { Box } from '@mui/material';
import Header from "../../components/Header";

const Arrays = (data, fieldName, fieldValue) => {
    let arrayItem = [];
    if (data && Array.isArray(data)) {
        data.map((item, key) => {
            arrayItem.push({ label: item[fieldName], value: item[fieldValue] });
            return null;
        });
    }
    return arrayItem;
};


const WriteProducts = () => {
  const state = useLocation().state;
  //console.log(state);
  const navigate = useNavigate();
  //  const { id } = state?.id; //match.params;
  const [id, setId] = useState(state?.id || 0);
  const [imagePreviews, setImagePreviews] = useState([]);
  //console.log(id);
  const [value, setValue] = useState(state?.description || "");
   const [description, setDescription] = useState(state?.description || "");
  //console.log(state?.title);
  const [slug, setSlug] = useState(state?.slug || "");
  const [active, setActive] = useState(state?.active || false);
  const [title, setTitle] = useState(state?.title || "");
  const [file, setFile] = useState(null);
  const [pimg, setPimg] = useState(state?.image || "");
  const [price, setPrice] = useState(state?.price || 0);
  const [categoryId, setCategoryId] = useState(Arrays(state?.Categories , "title", "id")|| []);
  const [allmain, setAllmain] = useState(null);
  const [allcateg, setAllcateg] = useState(null);
  const isAddMode = !id;
  const authUser = useSelector(x => x.auth.user);
  const initialValues = {
    title: title,
    description: value,
    price: price,
    slug: slug,
    image: pimg,
    active: active,
    CategoryId:categoryId
  };


 const dispatch = useDispatch();

  useEffect(() => {

   CategoriesService.getAll().then(x => setAllmain(x));

  }, []);
    //const { categories } = useSelector(x => x.categories);

   	//const { categories } = useSelector(x => x.categories);
	//if(categories) {
	//  setAllmain(categories);
	//}
//console.log(allmain);
   if(allmain && !allcateg) {
   //console.log(allmain.data);
   setAllcateg(Arrays(allmain.data, "title", "id"));
//console.log(allcateg);
};
//   //console.log(categories);
 //useSelector(x => x.categories);
////console.log(categ );



 const filesharhe_ref = useRef();
  const upload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await UploadService.upload(file);
      // setPimg(res.data.message]);
      //console.log(res.data.message);
      return res.data;
    } catch (err) {
      //console.log(err);
      toast.error(err, {
        position: toast.POSITION.TOP_LEFT
      });
    }
  };

  async function loadimg() {

    if (file) {
      const imgUrl = await upload();
    };

  }

  const handleClick = async (e) => {
    e.preventDefault();
    setFile(e.target.files[0]);
    setPimg(e.target.files[0].name);
    let images = [];

    for (let i = 0; i < e.target.files.length; i++) {
      images.push(URL.createObjectURL(e.target.files[i]));
    }

    //  setSelectedFiles(event.target.files);
    setImagePreviews(images);
   // //window.alert(imagePreviews);
  //  //window.alert(JSON.stringify({ imagePreviews }));
    /* if(file) {
     const imgUrl = await upload();
          setPimg(imgUrl.message);
     // //window.alert(pimg);
     // fields["img"]=pimg;
   };*/
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required('Nume este obligatoriu'),

 myfile: Yup.mixed().test('2097152', "Fișierul încărcat este prea mare."
                ,(value) => {
                   return(
                    value && filesharhe_ref.current ?
                        (filesharhe_ref.current.files[0].size<=2097152? true: false)
                         : true)
                }),

  });

  function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    loadimg();

    //console.log(fields);
    fields["description"] = value;
    fields["image"] = pimg;
    fields["active"] = active;


    fields["slug"] = slugify(fields["title"]);
   //window.alert(JSON.stringify({ fields }));
    if (isAddMode) {
      createProducts(fields, setSubmitting);
    } else {
      updateProducts(id, fields, setSubmitting);
    }
  }

  function createProducts(fields, setSubmitting) {

    ProductsService.create(fields)
      .then(() => {
        toast.success("Produs adăugat cu succes");
        alertService.success('Produs adăugat cu succes', { keepAfterRouteChange: true });

        navigate("/products");
      })
      .catch(error => {
        setSubmitting(false);
	//console.log(error);
	toast.error(error.response.data.message);

        alertService.error(error);


      });
  }

  function updateProducts(id, fields, setSubmitting) {
//console.log("Update"+id);
    ProductsService.update(id, fields)
      .then(() => {
        alertService.success('Update successful', { keepAfterRouteChange: true });
        toast.success("Actualizare cu succes", {
          position: toast.POSITION.TOP_RIGHT
        });
        navigate("/products");
      })
      .catch(error => {
        setSubmitting(false);
	//console.log(error.response.data.message);
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        });
        alertService.error(error);
      });
  }



  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ errors, touched, isSubmitting, setFieldValue }) => {
        return (
	 <Box m="20px">
          <Form>
            <h1>{isAddMode ? 'Adaugă Produs' : 'Editează  Produs'}</h1>
            <div className="form-row">
              <div className="form-group col-9">
                <label>Nume</label>
                <Field name="title" type="text"  className={'form-control' + (errors.title && touched.title ? ' is-invalid' : '')} />
                <ErrorMessage name="title" component="div" className="invalid-feedback" />
              </div>
              <div className="form-group col-3">
                <label>Pret</label>
                <Field name="price" type="number"  step="any" className={'form-control' + (errors.price && touched.price ? ' is-invalid' : '')} />
                <ErrorMessage name="price" component="div" className="invalid-feedback" />
              </div>
	     </div>
	<div className="form-row">

	      <div className="form-group col-5">
		       <label>Status {active ? (
                        <div className="badge badge-primary">Activ</div>
                      ) : (
                        <div className="badge badge-danger">Inactiv</div>
                      )}

		        <Field
                          style={{ marginLeft: "1.5em", marginTop: "1.3em" }}
                          type="checkbox"
                          name="active"  onChange={(e) => setActive(!active)} className={'form-control d-none' +  (errors.active && touched.active ? ' is-invalid' : '')}
                        />
                                <ErrorMessage name="active" component="div" className="invalid-feedback" />

                         </label>
             </div>

	                    <div className="form-group col-7">
<small>Sunt acceptate poze doar cu extensia jpg, gif, png, webp sub 2Mb. Rezolutie recomandata 800x400</small>
              </div>
             </div>

	     <div className="form-row">
              <div className="form-group col">
                <div className="editorContainer">
                  <label>Descriere</label>
                  <Field name="description" value={value} type="hidden" className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')} />
                  <ReactQuill
                    className="editor"
                    theme="snow"
                    value={value}
                    onChange={setValue}
                  />
                  <ErrorMessage name="description" component="div" className="invalid-feedback" />
                </div>
              </div>
            </div>

            <div className="form-row">
            <div className="form-group col-8">
                <label></label>
                <Field name="image" type="hidden"  value={pimg} className={'form-control' + (errors.img && touched.img ? ' is-invalid' : '')} />
                <ErrorMessage name="image" component="div" className="invalid-feedback" />


                <label className="file btn btn-sm btn-info" htmlFor="file">Incarca imaginea</label>

		    <Field innerRef={filesharhe_ref} type="file" id="file" name="myfile" onChange={handleClick}
                  accept=".jpg, .png, .gif, .svg, .webp"/>
                  <ErrorMessage name='myfile'  />
                {pimg && (!file) && (
                  <img src={process.env.REACT_APP_API_URL + "/test/files/" + pimg} alt={pimg} height="30vh" align="center" className='img-fluid img-responsive' />

                )}

                {imagePreviews && (
                  <>
                    {imagePreviews.map((img, i) => {
                      return (
                        <img src={img} alt={"image-" + i} key={i} height="80px" align="center" className='img-fluid img-responsive' />
                      );
                    })}
                  </>
                )}

              </div>

              <div className="form-group col-4">
                <label>Categorie</label>
 <Field name="CategoryId">
      {({ field, form, meta }) => (
                <Select
                  {...field}
                  isMulti
                  id="CategoryId"

                  onChange={(values) => form.setFieldValue("CategoryId", values)} 

                  options={allcateg}
                  className="basic-multi-select"
                  classNamePrefix="select"  
                  />
     )}
 </Field>

                <ErrorMessage name="CategoryId" component="div" className="invalid-feedback" />
              </div>

		      </div>
  <div className="form-row">
              <div className="form-group col-7">
                <label>Seo Slug</label>
 
                <Field name="slug" type="text" value={slug} readOnly className={'form-control' + (errors.slug && touched.slug ? ' is-invalid' : '')} />

                <ErrorMessage name="slug" component="div" className="invalid-feedback" />
              </div>
            </div>

            <div className="form-group">
              <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                Salvează
              </button>
              <Link to={isAddMode ? '/products' : '/products'} className="btn btn-link">Abandon</Link>
            </div>
          </Form>
	 </Box>
        );
      }}
    </Formik>
  );
}

export { WriteProducts };