import React from 'react';
import { Link, useParams  } from 'react-router-dom';
import UploadImages from "./ImagesUpload";
function Overview({ match }) {
    const { path } = {params: useParams()};// match;
    //console.log(path);

    return (
    <div className="container">
        <div>
            <h1>Setari</h1>
            <p>Această secțiune poate fi accesată doar de administratori</p>
	    	     <p><Link to={`/sitemap`}>Generare Sitemap</Link></p>
            <p><Link to={`/admin/users`}>Utilizatori</Link></p>
	    <p><Link to={`/admin/posts`}>Articole</Link></p>
	     <p><Link to={`/admin/pages`}>Pagini</Link></p>
        </div>
	<div className="container text-center">
        <h3>Media</h3>
      </div>
	      <div className="container" style={{ width: "600px" }}>
      <div className="my-3">
        <h3>pizzeriaclinceni.ro</h3>
        <h4>Încărcați mai multe imagini</h4>
      </div>

      <UploadImages />
    </div>
    </div>
    );
}

export { Overview };