import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { accountService } from '../_services';
import { history } from '_helpers';

export { PrivateRoute };

function PrivateRoute({ children }) {
    const { user: authUser } = useSelector(x => x.auth);
    //accountService.userValue;
    //useSelector(x => x.auth);
    //const user = accountService.userValue;
   // //console.log(accountService.userValue);
    
    if (!authUser) {

        // not logged in so redirect to login page with the return url
        return <Navigate to="/login" state={{ from: history.location }} />
    }
if (!authUser?.role === "admin") {
    // authorized so return child components
    return <Navigate to="/login"  />
    return  children;
    } 
    return  children;
}