import React from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { accountService, alertService } from '../_services';
import AuthLayout from "../hoc/authLayout";
import { toast } from 'react-toastify';

function ForgotPassword() {
    const initialValues = {
        email: ''
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('adresa de e-mail este invalida')
            .required('E-mailul este obligatoriu')
    });

    function onSubmit({ email }, { setSubmitting }) {
        alertService.clear();
        accountService.forgotPassword(email)
            .then(() => toast.success('Vă rugăm să vă verificați e-mailul pentru instrucțiuni de resetare a parolei'))
            .catch(error => toast.error(error))
            .finally(() => setSubmitting(false));
    }

    return (
            <AuthLayout>
             <div className="container">
               <div className="row">
                <div className="col-sm-8 offset-sm-2 mt-5">
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ errors, touched, isSubmitting }) => (
                <Form>
                    <h3 className="card-header">Aţi uitat parola</h3>
                    <div className="card-body">
                        <div className="form-group">
                            <label>Email</label>
                            <Field name="email" placeholder="Emailul tau.." type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                        </div>
                        <div className="form-row">
                            <div className="form-group col">
                                <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    Trimite
                                </button>
                                <Link to="login" className="btn btn-link">Abandon</Link>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>  
	       </div>
            </div>
        </div>
	</AuthLayout>
    )
}

export { ForgotPassword }; 