import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useConfirm } from "material-ui-confirm";
import { CategoriesService } from '../../_services';
import { categoriesActions } from '../../_store';
import Header from "../../components/Header";
import { Box, Typography, useTheme } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  roRO,
} from '@mui/x-data-grid';
import { tokens } from "../../theme";
import { DeleteOutline } from '@mui/icons-material';
import ShopProduct from "../products/ShopProduct";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function CategoriesList({ match }) {
const confirm = useConfirm();
  const theme = useTheme();
   const dispatch = useDispatch();
  const colors = tokens(theme.palette.mode);
    const { path } = useLocation();// match;

  const [categories, setCategories] = useState(null);
   const [currentPage, setCurrentPage] = useState(null);
///  const { categories } = useSelector(x => x.categories);
  useEffect(() => {

   CategoriesService.getAllwithproducts().then(x => setCategories(x.data));
  //      dispatch(categoriesActions.getAll());
  // //console.log(categories.data.data);
  }, []);
////console.log(categories);
////console.log(categorii);

const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) => categories.find((row) => row.id === id));
    console.log(selectedRowsData[0].Products.length);
    setCurrentPage(selectedRowsData);
   // console.log(JSON.stringify(selectedRowsData));
        console.log(JSON.stringify(currentPage));
	//console.log(currentPage[0].slug);

	{currentPage ? console.log("Produse : " + JSON.stringify(currentPage[0].slug + currentPage[0].Products)) : console.log("Nici o categorie")};
  };


 const deleteCategories = (id) => {
     confirm({title:'Esti Sigur', description: `Se va șterge definitiv Categoria ${id}.`,confirmationText:'da',cancellationText:'renunta' })
      .then(() => {
          setCategories(categories.map(x => {
            if (x.id === id) { x.isDeleting = true; }
            return x;
        }));
        CategoriesService.remove(id).then(() => {
            setCategories(categories => categories.filter(x => x.id !== id));
        });
      })
      .catch(() => {
       //console.log("Deletion cancelled.")
      });
  };


      const columns = [
    { field: "id", headerName: "Id", hide: true  },
    {
      field: "title",
      headerName: "Nume",
      width: 200,
      cellClassName: "name-column--cell",
    },
{
    field: 'image',
    headerName: 'Poza',
    width: 90,
      renderCell: (params: any) => {
        return (
          <>
{params.row.image  ? (
                         <img src={process.env.REACT_APP_API_URL + "/products/image/" + params.row.image} className="img-fluid rounded" width="60px" />
                      ) : (
                         <img src="assets/logo.png" className="img-fluid rounded" width="60px" />
                      )}
          </>
        )
      }
  },

 {
    field: 'main',
    headerName: 'Main',
    width: 90,
      renderCell: (params: any) => {
        return (
          <>
{params.row.main  ? (
                        <div className="badge badge-primary">Da</div>
                      ) : (
                        <div className="badge badge-danger">Nu</div>
                      )}
          </>
        )
      }
  },
  {
    field: 'Products',
    headerName: 'Produse',
    width: 90,
      renderCell: (params: any) => {
        return (
          <>
{params.row.Products.length  ? (
                        <div className="badge badge-primary">{params.row.Products.length}</div>
                      ) : (
                        <div className="badge badge-danger">0</div>
                      )}
          </>
        )
      }
  },
 {
    field: 'slider',
    headerName: 'Slider',
    width: 90,
      renderCell: (params: any) => {
        return (
          <>
{params.row.slider  ? (
                        <div className="badge badge-success">Da</div>
                      ) : (
                        <div className="badge badge-info">Nu</div>
                      )}
          </>
        )
      }
  },
{
      field: 'action',
      headerName: '',
      width: 100,
      renderCell: (params: any) => {
        return (
          <>
            <Link to={`/admin/categories/write`} state={params.row} >
              <button className="btn btn-sm btn-primary mr-1"><i className="fas fa-edit"></i></button>
            </Link>
	    <DeleteOutline className='btn btn-xs btn-sm btn-danger' style={{ width: '30px', height : '30px' }} onClick={() => deleteCategories(params.row.id)}/>

          </>
        )
      }
    }
  ];

function getStatus(params) {
//console.log(params);
 return `${!params.row.active || 'Activ'} ${params.row.active || 'Inactiv'}`

};

    return (
        <Box m="20px">
        <div>
            <h1>Categorii Produse</h1>

            <Link to={`/admin/categories/write`} className="btn btn-sm btn-success mb-2">Adăugați o Categorie</Link>
	            
	     {categories && <Box
        m="8px 0 0 0"
        height="80vh"
	width= "90%"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiChackbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >

	  <DataGrid
               rows={categories}
               columns={columns}
	       pageSize={5}
	       localeText={roRO.components.MuiDataGrid.defaultProps.localeText} 
               components={{ Toolbar: CustomToolbar }}
	       onSelectionModelChange={(ids) => {
          //console.log(ids);
	  //console.log(ids[0]);
onRowsSelectionHandler(ids);
	 // //console.log(params);
        }}
        />
      </Box> }
 


<div className="col-md-6">
        {currentPage ? (
          <div>
 
            <div>
              <label>
                <strong>Lista Produse la categoria:</strong>
              </label>{" "}
              {currentPage[0].title}
            </div>

            <div>


	  <ShopProduct bestFood={currentPage[0]} />
           
   

            </div>



          
          </div>
        ) : (
          <div>
            <br />
            <p>Vă rugăm să faceți clic pe un nume...</p>
          </div>
        )}
      </div>


        </div>
        </Box>
    );
}

export { CategoriesList };