import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { Overview } from './Overview';
import { Users } from './users';
import { Posts } from './posts';

function Admin({ match }) {
    const { path } = useLocation();
    //console.log(useLocation());

    return (
        <div className="p-4">
	<p></p>
	<Overview />
	{/*
            <div className="container">
                <Routes>
                    <Route exact path={path} component={Overview} />
                    <Route path={`${path}/users`} component={Users} />
		    <Route path={`${path}/posts`} component={Posts} />
                </Routes>
            </div>*/}
        </div>
    );
}

export { Admin };