import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
 import { Box } from '@mui/material';
import { accountService, alertService } from '../_services';
import { useSelector, useDispatch } from 'react-redux';

import { authActions } from '_store';
import { toast } from 'react-toastify';
import { useConfirm } from "material-ui-confirm";


function Update({ history }) {
const confirm = useConfirm();
    const authUser = useSelector(x => x.auth.user);
    const initialValues = {
        username: authUser.username,
        firstName: authUser.firstName,
        lastName: authUser.lastName,
        email: authUser.email,
	phone:authUser.phone,
	address:authUser.address,
	postal_code:authUser.postal_code,
        password: '',
        confirmPassword: ''
    };

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required('First Name is required'),
        lastName: Yup.string()
            .required('Last Name is required'),
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters'),
        confirmPassword: Yup.string()
            .when('password', (password, schema) => {
                if (password) return schema.required('Confirm Password is required');
            })
            .oneOf([Yup.ref('password')], 'Passwords must match')
    });

    function onSubmit(fields, { setStatus, setSubmitting }) {
        setStatus();
        accountService.update(authUser.id, fields)
            .then(() => {
                alertService.success('Update successful', { keepAfterRouteChange: true });
		toast.success('Actualizare cu succes');
                history.push('.');
            })
            .catch(error => {
                setSubmitting(false);
                alertService.error(error);
		toast.error(error);
            });
    }

    const [isDeleting, setIsDeleting] = useState(false);
    function onDelete() {
    confirm({title:'Esti Sigur', description: `Se va șterge definitiv contul ${authUser.username}.`,confirmationText:'da',cancellationText:'renunta' })
      .then(() => {
              setIsDeleting(true);
            accountService.delete(authUser.id)
                .then(() => toast.success('Contul a fost șters cu succes'));
        });

    }

    return (
    <Box m="20px">
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ errors, touched, isSubmitting }) => (
                <Form>
                    <h3>Actualizati profilul</h3>
                    <div className="form-row">
                            <div className="form-group col">
                                <label>NickName</label>
                                <Field name="username" readOnly type="text" className={'form-control' + (errors.username && touched.username ? ' is-invalid' : '')} />
                                <ErrorMessage name="fusername" component="div" className="invalid-feedback" />
                            </div>
                        <div className="form-group col-5">
                            <label>Nume</label>
                            <Field name="firstName" type="text" className={'form-control' + (errors.firstName && touched.firstName ? ' is-invalid' : '')} />
                            <ErrorMessage name="firstName" component="div" className="invalid-feedback" />
                        </div>
                        <div className="form-group col-5">
                            <label>Prenume</label>
                            <Field name="lastName" type="text" className={'form-control' + (errors.lastName && touched.lastName ? ' is-invalid' : '')} />
                            <ErrorMessage name="lastName" component="div" className="invalid-feedback" />
                        </div>
                    </div>
		    <div className="form-row">
                    <div className="form-group col-7">
                        <label>Email</label>
                        <Field name="email" readOnly type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                    </div>
		    <div className="form-group col-5">
                                <label>Telefon</label>
                                <Field name="phone" type="text" className={'form-control' + (errors.phone && touched.phone ? ' is-invalid' : '')} />
                                <ErrorMessage name="phone" component="div" className="invalid-feedback" />
                    </div>
		    </div>
		    <div className="form-row">

                            <div className="form-group col-9">
                                <label>Adresa</label>
                                <Field name="address" type="text" className={'form-control' + (errors.address && touched.address ? ' is-invalid' : '')} />
                                <ErrorMessage name="address" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group col-3">
                                <label>Cod Postal</label>
                                <Field name="postal_code" type="text" className={'form-control' + (errors.postal_code && touched.postal_code ? ' is-invalid' : '')} />
                                <ErrorMessage name="postal_code" component="div" className="invalid-feedback" />
                            </div>
                        </div>
                    <h3 className="pt-3">Schimbaţi parola</h3>
                    <p>Lăsați necompletat pentru a păstra aceeași parolă</p>
                    <div className="form-row">
                        <div className="form-group col">
                            <label>Parola</label>
                            <Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                            <ErrorMessage name="password" component="div" className="invalid-feedback" />
                        </div>
                        <div className="form-group col">
                            <label>Confirma Parola</label>
                            <Field name="confirmPassword" type="password" className={'form-control' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')} />
                            <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                        </div>
                    </div>
                    <div className="form-group">
                        <button type="submit" disabled={isSubmitting} className="btn btn-primary mr-2">
                            {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                            Modifica
                        </button>
                        <button type="button" onClick={() => onDelete()} className="btn btn-danger" style={{ width: '75px' }} disabled={isDeleting}>
                            {isDeleting
                                ? <span className="spinner-border spinner-border-sm"></span>
                                : <span>Sterge</span>
                            }
                        </button>
                        <Link to="/" className="btn btn-link">Abandon</Link>
                    </div>
                </Form>
            )}
        </Formik>
	</Box>
    )
}

export { Update };