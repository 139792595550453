import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {Link, useNavigate } from 'react-router-dom';
//import { createPage } from "../slices/posts";
import { createPage } from "../../_store/pages";
//import ReactQuill from "react-quill";
//import "react-quill/dist/quill.snow.css";
import RichTextEditor from '../../_helpers/RichTextEditor';
import "../style.scss";
import { toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import { Box } from '@mui/material';
import Header from "../../components/Header";
import slugify from 'react-slugify';


const AddPage = () => {
  const initialPageState = {
    id: null,
    title: "",
    description: "",
    published: false
  };
	  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [Page, setPage] = useState(initialPageState);
  const [submitted, setSubmitted] = useState(false);

  const dispatch = useDispatch();

  const handleInputChange = event => {
    const { name, value } = event.target;
    setPage({ ...Page, [name]: value });
  };
  const handleContentChange = contentHtml => {
	setValue(contentHtml);
			    };
  const savePage = () => {
    //  const { title, description } = Page;
    const title = Page.title;
    const slug = slugify(title);
    const description = value;
    dispatch(createPage({ title, description, slug }))
      .unwrap()
      .then(data => {
        //console.log(data);
        setPage({
          id: data.id,
          title: data.title,
          description: data.description,
          published: data.published
        });
        setSubmitted(true);
		toast.success("Pagina a fost adaugata cu succes!");
		navigate("/admin/pages");
      })
      .catch(e => {
        //console.log(e);
		toast.error(e)
      });
  };

  const newPage = () => {
    setPage(initialPageState);
    setValue("");
    setSubmitted(false);
  };

  return (
   <Box m="20px">
    <div className="submit-form">
      {submitted ? (
        <div>
          <h4>Ați trimis cu succes!</h4>
          <button className="btn btn-success" onClick={newPage}>
            Adauga
          </button>
        </div>
      ) : (
        <div>
          <div className="form-group">
            <label htmlFor="title">Titlu</label>
            <input
              type="text"
              className="form-control"
              id="title"
              required
              value={Page.title || ''}
              onChange={handleInputChange}
              name="title"
            />
          </div>

          <div className="form-group">
            <label htmlFor="description">Descriere</label>


		   <RichTextEditor
            content={value}
            handleContentChange={handleContentChange}
            placeholder="introduceți text aici..."
            />
          </div>

          <button onClick={savePage} className="btn btn-success">
            Trimite
          </button>
			   <Link to='/admin/pages' className="btn btn-link">Abandon</Link>
        </div>
      )}
    </div>
 </Box>
  );
};

//export default AddPage;
export { AddPage };
