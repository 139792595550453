import React from "react";
import { useState } from 'react';
import { Box, useTheme } from "@mui/material";
//import { DataGrid, GridToolbar, roRO } from "@mui/x-data-grid";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  roRO,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import { tokens } from "../../theme";
import { mockDataContacts } from "../../data/mockData";
import { DeleteOutline } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';

import Header from "../../components/Header";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const localizedTextsMap = {
  toolbarDensity: "densitate",
  toolbarDensityComfortable: "Confortabil",
  toolbarExport: "export",
  columnMenuUnsort: "Nesortat",
  columnMenuSortAsc: "Sortați în ordine crescătoare",
  columnMenuSortDesc: "Sortați în ordine descrescătoare",
  columnMenuFilter: "Filtru",
  columnMenuHideColumn: "Ascunde",
  columnMenuShowColumns: "Afișați coloanele",
  MuiTablePagination: {
      labelDisplayedRows: ({ from, to, count }) =>
        `${from} - ${to} din ${count}`,
    },
};

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

const Contacts = () => {
  const theme = useTheme();
 const [data, setData] = useState(mockDataContacts);
  const colors = tokens(theme.palette.mode);
  const handleDelete = (id: string | number) => {
    setData(data.filter(item => item.id !== id))
  }

  const columns = [
    { field: "id", headerName: "Id", width: 100, hide: true },
    { field: "registrarId", headerName: "Registrar Id", width: 100, hide: true  },
    {
      field: "name",
      headerName: "Nume",
      cellClassName: "name-column--cell",
      width: 200,
    },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      headerAlign: "left",
      align: "left",
      width: 100,
      hide: true, 
    },
    { field: "phone", headerName: "Telefon", width: 100 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "address", headerName: "Adresa", width: 250 },
    { field: "city", headerName: "City", width: 100,hide: true },
    { field: "zipCode", headerName: "Cod Postal", width: 100 },
    {
      field: 'action',
      headerName: '',
      width: 150,
      renderCell: (params: any) => {
        return (
          <>
	              <Link to={`/admin/users/write/`} state={params.row} >
              <button className="btn btn-xs btn-sm btn-primary mr-1" ><i className="fas fa-edit"></i></button>
            </Link>

            <DeleteOutline className='btn btn-xs btn-sm btn-danger' style={{ width: '30px', height : '30px' }} onClick={() => handleDelete(params.row.id)}/>
          </>
        )
      }
    }
  ];
  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Clienti" subtitle="Lista clienti care au comandat" />
      </Box>
      <Box
        m="8px 0 0 0"
        width="100%"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
        rows={data}
          columns={columns}
	  pageSize={5}
	  localeText={roRO.components.MuiDataGrid.defaultProps.localeText} 
          components={{ Toolbar: CustomToolbar, Pagination: CustomPagination }}

        />
      </Box>
    </Box>
  );
};

export default Contacts;
