import http from "../http-common";

const getAll = () => {
  return http.get("/products/list");
};

const get = id => {
  return http.get(`/products/id/${id}`);
};

const getbyslug = slug => {
  return http.get(`/products/slug/${slug}`);
};
const getallcategory = slug => {
  return http.get(`/products/category/${slug}`);
};
const getbyname = name => {
  return http.get(`/products/name/${name}`);
};

const create = data => {
  return http.post("/products", data);
};

const update = (id, data) => {
	//console.log(id);
  return http.put(`/products/${id}`, data);
};

const remove = id => {
  return http.delete(`/products/${id}`);
};

const removeAll = () => {
  return http.delete(`/products`);
};

const findByTitle = title => {
  return http.get(`/products?title=${title}`);
};

const ProductsService = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  getbyslug,
  getbyname,
  findByTitle
};

//export default PageService;
export {ProductsService};