import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation, useSearchParams  } from 'react-router-dom';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import { accountService, alertService } from '../_services';

function VerifyEmail({ history }) {
    const EmailStatus = {
        Verifying: 'Verifying',
        Failed: 'Failed'
    };
 const location = useLocation();
 const navigate = useNavigate();
 const [searchParams, setSearchParams] = useSearchParams();
    const [emailStatus, setEmailStatus] = useState(EmailStatus.Verifying);

    useEffect(() => {
        const { token } = queryString.parse(location.search);

/*	if (searchParams.has("t")) {
      const t = searchParams.get("token");
      if (t) {
        searchParams.delete("token");
        console.log("setting params:", {
          searchParams: searchParams.toString()
        });
        console.dir(searchParams.toString());
        setSearchParams(searchParams);
      } */

        // remove token from url to prevent http referer leakage
      //  history.replace(location.pathname);

        accountService.verifyEmail(token)
            .then(() => {
                alertService.success('Verificare reușită, acum vă puteți autentifica', { keepAfterRouteChange: true });
		toast('Verificare reușită, acum vă puteți autentifica');
                navigate('/login');
            })
            .catch(() => {
                setEmailStatus(EmailStatus.Failed);
            });
    }, []);

    function getBody() {
        switch (emailStatus) {
            case EmailStatus.Verifying:
                return <div>Se verifică...</div>;
            case EmailStatus.Failed:
                return <div>Verificarea a eșuat, vă puteți verifica și contul folosind pagina <Link to="forgot-password">Aţi uitat parola</Link>.</div>;
        }
    }

    return (
        <div>
            <h3 className="card-header">Verify Email</h3>
            <div className="card-body">{getBody()}</div>
        </div>
    )
}

export { VerifyEmail }; 