import React, { useState, useEffect, useRef } from "react";
import UploadService from "../_services/FileUploadService";
import { useNavigate } from "react-router-dom";
import {

  CloseButton
} from '../components/styles/CalendarStyles';
import Modal from "react-modal";
import Imgligth from "./Imgligth";


const UploadImages = () => {
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [imageSelect, setImageSelect] = useState();
  const [progressInfos, setProgressInfos] = useState({ val: [] });
  const [message, setMessage] = useState([]);
  const [imageInfos, setImageInfos] = useState([]);
  const progressInfosRef = useRef(null);
    const history = useNavigate();
const [modalOpen, setModalOpen] = useState(false);
const [selectedModal, setSelectedModal] = useState("");
const [selectedLight, setSelectedLight] = useState("");
 const customModalStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      },
      overlay: {zIndex: 1000}
    };
    const openForm = () => {
      setSelectedModal("Imgligth");
      openModal();
    }
  
    // Open Modal Function
    const openModal = () => {
      setModalOpen(true);
    };
  
    // Close Modal Function
    const closeModal = () => {
      setModalOpen(false);
    };

    const handleEventClick = (img) => {
      if(img) {
        console.log("handleEventClick",img);
       // dispatch(search_appointment(clickInfo.event._def.publicId));
       setSelectedLight(img);
        setSelectedModal("Imgligth");

        openModal();
      }
    }

  useEffect(() => {
    UploadService.getFiles().then((response) => {
      setImageInfos(response.data);
    });
  }, []);

  const selectFiles = (event) => {
    let images = [];

    for (let i = 0; i < event.target.files.length; i++) {
      images.push(URL.createObjectURL(event.target.files[i]));
    }

    setSelectedFiles(event.target.files);
    setImagePreviews(images);
    setProgressInfos({ val: [] });
    setMessage([]);
  };

  const upload = (idx, file) => {
    let _progressInfos = [...progressInfosRef.current.val];
    return UploadService.upload(file, (event) => {
      _progressInfos[idx].percentage = Math.round(
        (100 * event.loaded) / event.total
      );
      setProgressInfos({ val: _progressInfos });
    })
      .then(() => {
        setMessage((prevMessage) => [
          ...prevMessage,
          "Imaginea a fost încărcată cu succes: " + file.name,
        ]);
      })
      .catch(() => {
        _progressInfos[idx].percentage = 0;
        setProgressInfos({ val: _progressInfos });

        setMessage((prevMessage) => [
          ...prevMessage,
          "Nu s-a putut încărca imaginea: " + file.name,
        ]);
      });
  };

  const uploadImages = () => {
    const files = Array.from(selectedFiles);

    let _progressInfos = files.map((file) => ({
      percentage: 0,
      fileName: file.name,
    }));

    progressInfosRef.current = {
      val: _progressInfos,
    };

    const uploadPromises = files.map((file, i) => upload(i, file));

    Promise.all(uploadPromises)
      .then(() => UploadService.getFiles())
      .then((files) => {
        setImageInfos(files.data);
      });

    setMessage([]);
  };

  const deletePoza = async (name) => {
if (window.confirm("Stergi imaginea? "+name))
{ 

////window.alert(name);
	 //console.log("sterge "+name);
	// history("/");
 //let _progressInfos = [...progressInfosRef.current.val];
    return UploadService.deleteimg(name, (event) => {

    })
      .then(() => {
        setMessage((prevMessage) => [
          ...prevMessage,
          "Imaginea a fost stearsa cu succes: " + name,
        ]);
		     UploadService.getFiles().then((response) => {
      setImageInfos(response.data);
    });
      })
      .catch(() => {
      //  _progressInfos[10].percentage = 0;
      //  setProgressInfos({ val: _progressInfos });

        setMessage((prevMessage) => [
          ...prevMessage,
          "Nu s-a putut stergev imaginea: " + name,
        ]);
      });
} 
   };

  return (
    <div>
      <div className="row">
        <div className="col-8">
          <label className="btn btn-default p-0">
            <input
              type="file"
              multiple
              accept="image/*"
              onChange={selectFiles}
            />
          </label>
        </div>

        <div className="col-4">
          <button
            className="btn btn-success btn-sm"
            disabled={!selectedFiles}
            onClick={uploadImages}
          >
            Upload
          </button>
        </div>
      </div>

      {progressInfos &&
        progressInfos.val.length > 0 &&
        progressInfos.val.map((progressInfo, index) => (
          <div className="mb-2" key={index}>
            <span>{progressInfo.fileName}</span>
            <div className="progress">
              <div
                className="progress-bar progress-bar-info"
                role="progressbar"
                aria-valuenow={progressInfo.percentage}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: progressInfo.percentage + "%" }}
              >
                {progressInfo.percentage}%
              </div>
            </div>
          </div>
        ))}

      {imagePreviews && (
        <div>
          {imagePreviews.map((img, i) => {
            return (
              <img className="preview" src={img} alt={"image-" + i} key={i} height="200px" />
            );
          })}
        </div>
      )}

      {message.length > 0 && (
        <div className="alert alert-secondary mt-2" role="alert">
          <ul>
            {message.map((item, i) => {
              return <li key={i}>{item}</li>;
            })}
          </ul>
        </div>
      )}

      {imageInfos.length > 0 && (
        <div className="card mt-3">
          <div className="card-header">Lista de imagini</div>
          <ul className="list-group list-group-flush">
            {imageInfos &&
              imageInfos.map((img, index) => (
                <li className="list-group-item" key={index}>
                  
                    <a href={img.url}>{img.name}</a>
             <button className="badge badge-danger mr-2 ml-3 pull-right" onClick={ () => deletePoza(img.name) } >
              <span><i className="fa fa-trash" aria-hidden="true"></i></span>
            </button>
                 
<p onClick={ () => handleEventClick(img.url) }>
                  <img src={img.url} alt={img.name} height="80px" /></p>
                </li>
              ))}
          </ul>
        </div>

      )}
  

  {modalOpen && (
          <Modal 
            isOpen={true}
            onRequestClose={closeModal}
            ariaHideApp={false}
            style={customModalStyles}
          >
            <CloseButton onClick={closeModal}>X</CloseButton>
            {
              <Imgligth img={selectedLight}/>
            }
          </Modal>
        )}

    
    </div>
  );
};

export default UploadImages;
