import React from 'react';
import { useLocation } from 'react-router-dom';

import { Details } from './Details';
//import { Update } from './Update';                   // <Route path={`${path}/update`} element={Update} />

function Profile() {
	   const { pathname } = useLocation(); 
    const { path } = pathname;
    //console.log(`${path}/update`);
	//console.log(path);
    return (
        <div className="p-4">
            <div className="container">
		<h1>Profil utilizator</h1>
		<Details />

            </div>
        </div>
    );
}

export { Profile };