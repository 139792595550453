import React from 'react';

import { toast } from 'react-toastify';




const Imgligth = ({img}) => {
  return (


                                        <div style={{alignItems:'center', justifyContent:'center'}} >
                                            <img src={`${img}`} alt="img" className="img-fluid"/>
                                        </div>

                                    
    )
}
export default Imgligth;
