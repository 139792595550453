import React from 'react';
import { Route, Routes, useLocation  } from 'react-router-dom';

import { List } from './List';
//import { AddEdit } from './AddEdit';             <Route path={`${path}/add`} component={AddEdit} />             <Route path={`${path}/edit/:id`} component={AddEdit} />

function Posts({ match }) {
    const { path } = useLocation();//match;
    
    return (
         <div>
     <List />
        <Routes>
            <Route exact path={path} component={List} />

        </Routes>
		 </div>
    );
}

export { Posts };