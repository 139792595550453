import http from "../http-common";


const getAll = () => {
  return http.get("/Pages");
};

const get = id => {
  return http.get(`/Pages/${id}`);
};

const register = data => {
	//console.log(JSON.stringify(data));
	//console.log(data.email);

  return http.post("/users", {

    "password": data.password,
    "username": data.username,
    "email": data.email,
    "firstName": data.firstName,
    "lastName": data.lastName,
    "role": "user",
    "confirmed":false
});
};

const update = (id, data) => {
  return http.put(`/Pages/${id}`, data);
};

const remove = id => {
  return http.delete(`/Pages/${id}`);
};

const removeAll = () => {
  return http.delete(`/Pages`);
};

const findByTitle = title => {
  return http.get(`/Pages?title=${title}`);
};

const AllService = {
  getAll,
  get,
  register,
  update,
  remove,
  removeAll,
  findByTitle
};

//export default PageService;
export {AllService};