import React from 'react';
import { Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';

import { authActions } from '_store';

function Details({  }) {
   //const { path } = match;
   const { path } = process.env.REACT_APP_API_URL;
    const authUser = useSelector(x => x.auth.user);
//console.log("Profil");
    return (
        <div>
 
            <p>
	        <strong>NickName: </strong> {authUser.username}<br />
		<strong>Nume: </strong> {authUser.firstName} {authUser.lastName}<br />
                <strong>Email: </strong> {authUser.email}<br />
                <strong>Telefon: </strong> {authUser.phone}<br />
                <strong>Adresa: </strong> {authUser.adress}<br />

            </p>
            <p><Link className="btn btn-sm btn-primary" to={`/profile/update`}>Actualizați profilul</Link></p>
        </div>
    );
}

export { Details };