import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useConfirm } from "material-ui-confirm";
import { ProductsService } from '../../_services';
import { productsActions } from '../../_store';
import Header from "../../components/Header";
import { Box, Typography, useTheme } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  roRO,
} from '@mui/x-data-grid';
import { tokens } from "../../theme";
import { DeleteOutline } from '@mui/icons-material';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function ProductsList({ match }) {
  const confirm = useConfirm();
  const theme = useTheme();
  const dispatch = useDispatch();
  const colors = tokens(theme.palette.mode);
  const { path } = useLocation();// match;

  const [products, setProducts] = useState(null);

  useEffect(() => {

  ProductsService.getAll().then(x => setProducts(x.data));
  //      dispatch(productsActions.getAll());
//setProducts(useSelector(x => x.products));
//setProduct(products);
  }, []);

// const { products } = useSelector(x => x.products);
////console.log({products});

const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) => products.find((row) => row.id === id));
    //console.log(selectedRowsData);
  };


 const deleteProducts = (id) => {
     confirm({title:'Esti Sigur', description: `Se va șterge definitiv Produsul ${id}.`,confirmationText:'da',cancellationText:'renunta' })
       .then(() => {
          setProducts(products.map(x => {
            if (x.id === id) { x.isDeleting = true; }
            return x;
        }));
        ProductsService.remove(id).then(() => {
            setProducts(users => users.filter(x => x.id !== id));
        });
      })
      .catch(() => {
       //console.log("Deletion cancelled.")
      });
  };


      const columns = [
    { field: "id", headerName: "Id", hide: true  },
    {
      field: "title",
      headerName: "Nume",
      width: 200,
      cellClassName: "name-column--cell",
    },
        {
      field: "price",
      headerName: "Pret",
      width: 100,
      cellClassName: "name-column--cell",
    },
{
    field: 'image',
    headerName: 'Poza',
    width: 90,
      renderCell: (params: any) => {
        return (
          <>
{params.row.image  ? (
                         <img src={process.env.REACT_APP_API_URL + "/products/image/" + params.row.image} className="img-fluid rounded" width="60px" />
                      ) : (
                         <img src="assets/logo.png" className="img-fluid" width="60px" />
                      )}
          </>
        )
      }
  },

 {
    field: 'active',
    headerName: 'Status',
    width: 90,
      renderCell: (params: any) => {
        return (
          <>
{params.row.active  ? (
                        <div className="badge badge-primary">Activ</div>
                      ) : (
                        <div className="badge badge-danger">Inactiv</div>
                      )}
          </>
        )
      }
  },

{
      field: 'action',
      headerName: '',
      width: 100,
      renderCell: (params: any) => {
        return (
          <>
            <Link to={`/admin/products/write`} state={params.row} >
              <button className="btn btn-sm btn-primary mr-1"><i className="fas fa-edit"></i></button>
            </Link>
	    <DeleteOutline className='btn btn-xs btn-sm btn-danger' style={{ width: '30px', height : '30px' }} onClick={() => deleteProducts(params.row.id)}/>

          </>
        )
      }
    }
  ];

function getStatus(params) {
//console.log(params);
 return `${!params.row.active || 'Activ'} ${params.row.active || 'Inactiv'}`

};

    return (
        <Box m="20px">
        <div>
            <h1>Produse</h1>

            <Link to={`/admin/products/write`} className="btn btn-sm btn-success mb-2">Adăugați un Produs</Link>
	            
	     {products && <Box
        m="8px 0 0 0"
        height="80vh"
	width= "90%"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiChackbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >

	  <DataGrid
               rows={products}
               columns={columns}
	       pageSize={5}
	       localeText={roRO.components.MuiDataGrid.defaultProps.localeText} 
               components={{ Toolbar: CustomToolbar }}
	       onSelectionModelChange={(ids) => {
          //console.log(ids);
	  //console.log(ids[0]);
onRowsSelectionHandler(ids);
	 // //console.log(params);
        }}
        />
      </Box> }
 
        </div>
        </Box>
    );
}

export { ProductsList };