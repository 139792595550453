import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import AuthLayout from "../hoc/authLayout";

import { accountService, alertService } from '../_services';
import { AllService } from '../_services';
function Register({ }) {
    const initialValues = {
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        acceptTerms: false
    };
const history = useNavigate();
    const validationSchema = Yup.object().shape({
       username: Yup.string()
            .required('Nickname este obligatoriu'),
        firstName: Yup.string()
            .required('Numne este obligatoriu'),
        lastName: Yup.string()
            .required('Prenume este obligatoriu'),
        email: Yup.string()
            .email('Adresa de e-mail este invalida')
            .required('Email este obligatoriu'),
        password: Yup.string()
            .min(6, 'parola trebuie sa aibă cel puțin 6 caractere')
            .required('Parola este necesara'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Parolele trebuie să se potrtivească')
            .required('Este necesară confirmarea parolei'),
        acceptTerms: Yup.bool()
            .oneOf([true], 'Este necesară acceptarea termenilor și condițiilor')
    });

    function onSubmit(fields, { setStatus, setSubmitting }) {
        setStatus();
        AllService.register(fields)
            .then(() => {
                alertService.success('Înregistrare reușită, vă rugăm să vă verificați e-mailul pentru instrucțiuni de verificare', { keepAfterRouteChange: true });
toast('Înregistrare reușită, vă rugăm să vă verificați e-mailul pentru instrucțiuni de verificare');
                history('/login');
            })
            .catch(error => {
	   // //window.alert(error.response.data.message);
	    	////console.log(JSON.stringify(error.response));
                setSubmitting(false);
                toast(error.response.data.message);
            });
    }

    return (
        <AuthLayout>
            <div className="container">
            <div className="row">
                <div className="col-sm-8 offset-sm-2 mt-5">
                    <div className="card m-3">
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ errors, touched, isSubmitting }) => (
                <Form>
                    <h3 className="card-header">Inregistreaza-te</h3>
                    <div className="card-body">
                        <div className="form-row">
                            <div className="form-group col">
                                <label>Nickname</label>
                                <Field name="username" type="text" className={'form-control' + (errors.username && touched.username ? ' is-invalid' : '')} />
                                <ErrorMessage name="username" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group col-5">
                                <label>Nume</label>
                                <Field name="firstName" type="text" className={'form-control' + (errors.firstName && touched.firstName ? ' is-invalid' : '')} />
                                <ErrorMessage name="firstName" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group col-5">
                                <label>Prenume</label>
                                <Field name="lastName" type="text" className={'form-control' + (errors.lastName && touched.lastName ? ' is-invalid' : '')} />
                                <ErrorMessage name="lastName" component="div" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                        </div>
                        <div className="form-row">
                            <div className="form-group col">
                                <label>Parola</label>
                                <Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                <ErrorMessage name="password" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group col">
                                <label>Confirma Parola</label>
                                <Field name="confirmPassword" type="password" className={'form-control' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')} />
                                <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                            </div>
                        </div>
                        <div className="form-group form-check">
                            <Field type="checkbox" name="acceptTerms" id="acceptTerms" className={'form-check-input ' + (errors.acceptTerms && touched.acceptTerms ? ' is-invalid' : '')} />
                            <label htmlFor="acceptTerms" className="form-check-label">Acceptați Termenii și Condițiile</label>
                            <ErrorMessage name="acceptTerms" component="div" className="invalid-feedback" />
                        </div>
                        <div className="form-group">
                            <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                Inregistreaza-te
                            </button>
                            <Link to="login" className="btn btn-link">Abandon</Link>
                        </div>
                    </div>
                </Form>


            )}
        </Formik>
			                    </div>
                </div>
            </div>
        </div>
	</AuthLayout>
    )
}

export { Register }; 