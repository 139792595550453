import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useConfirm } from "material-ui-confirm";
import { accountService } from '../../_services';

import Header from "../../components/Header";
import { Box, Typography, useTheme } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  roRO,
} from '@mui/x-data-grid';
import { tokens } from "../../theme";
import { DeleteOutline } from '@mui/icons-material';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function List({ match }) {
const confirm = useConfirm();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
    const { path } = useLocation();// match;
    const [users, setUsers] = useState(null);

    useEffect(() => {
       accountService.getAll().then(x => setUsers(x));
       //console.log(users);

    }, []);

 const deleteUser = (id) => {
     confirm({title:'Esti Sigur', description: `Se va șterge definitiv ${id}.`,confirmationText:'da',cancellationText:'renunta' })
      .then(() => {
          setUsers(users.map(x => {
            if (x.id === id) { x.isDeleting = true; }
            return x;
        }));
	console.log("aici"+id);
        accountService.delete(id).then(() => {
            setUsers(users => users.filter(x => x.id !== id));
        });
      })
      .catch(() => {
       console.log("Deletion cancelled.")
      });
  };


      const columns = [
    { field: "id", headerName: "Id", hide: true  },
    {
      field: "username",
      headerName: "NickName",
      width: 100,
      cellClassName: "name-column--cell",
    },
    { field: "firstName", headerName: "Nume", width: 100 },
    { field: "lastName", headerName: "Prenume", width: 100 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "role", headerName: "Rol", width: 80 },

 {
    field: 'active',
    headerName: 'Status',
    width: 90,
      renderCell: (params: any) => {
        return (
          <>
{params.row.active  ? (
                        <div className="badge badge-primary">Activ</div>
                      ) : (
                        <div className="badge badge-danger">Inactiv</div>
                      )}
          </>
        )
      }
  },

{
      field: 'action',
      headerName: '',
      width: 100,
      renderCell: (params: any) => {
        return (
          <>
            <Link to={`/admin/users/write/`} state={params.row} >
              <button className="btn btn-sm btn-primary mr-1"><i className="fas fa-edit"></i></button>
            </Link>
	    <DeleteOutline className='btn btn-xs btn-sm btn-danger' style={{ width: '30px', height : '30px' }} onClick={() => deleteUser(params.row.id)}/>

          </>
        )
      }
    }
  ];

function getStatus(params) {
//console.log(params);
 return `${!params.row.active || 'Activ'} ${params.row.active || 'Inactiv'}`

};

    return (
        <Box m="20px">
        <div>
            <h1>Utilizatori</h1>

            <Link to={`/admin/users/write`} className="btn btn-sm btn-success mb-2">Adăugați utilizator</Link>
	            
	     {users && <Box
        m="8px 0 0 0"
        height="80vh"
	width= "90%"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiChackbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >

	  <DataGrid
               rows={users}
               columns={columns}
	       pageSize={5}
	       localeText={roRO.components.MuiDataGrid.defaultProps.localeText} 
               components={{ Toolbar: CustomToolbar }}
        />
      </Box> }
 
        </div>
        </Box>
    );
}

export { List };