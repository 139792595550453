import React, { useEffect, useState  }  from "react";
import "./styles.css";
import {  Link, useLocation, useNavigate } from "react-router-dom";
export { Plans };
const planContents = [
  {
    header: "Comenzi",
    price: 585,
    features: [
      "In ultima saptamana"
    ],
    buttonLabel: "Detalii",
    link: "orders",
    cul: "primary", 
    outline: true
  },
  {
    header: "Clienti",
    price: 865,
    features: [
      "Care au platit "
    ],
    buttonLabel: "Lista",
    link: "contacts",
    cul: "info", 
    outline: false
  },
  {
    header: "Produse",
    price: 324,
    features: [
      "Produse active"
    ],
    buttonLabel: "Modifica",
    link: "products",
    cul: "success", 
    outline: false
  }

];

const Plan = props => {

  return (
	     <div
             className="col-md-6 col-lg-4 col-xl-4 text-center"
             style={{ marginTop: "2%" }} key={props.header}
                >
    <div className={"card mb-4 shadow-sm bg-gradient text-white bg-"+props.cul}>
      <div className="card-header">
        <h4 className="my-0 font-weight-normal">
          {props.header} 
        </h4>
      </div>
      <div className="card-body">
        <h1 className="card-title pricing-card-title">
          {`${props.price}`}
          <small className="text-muted">

          </small>
        </h1>
        <ul className="list-unstyled mt-3 mb-4">
          {props.features.map((feature, i) => (
            <li key={i}>{feature}</li>
          ))}
        </ul>
			<Link to={`/${props.link}`}>
 <button
          className={`btn btn-sm btn-danger  ${
            props.outline
              ? "btn-outline-info"
              : "btn-info"
          }`}
          type="button"
        >
          {props.buttonLabel}
        </button>
			</Link>
      </div>
    </div>
			</div>
  );
};

const Plans = () => {
const state = useLocation().state;
//console.log("ce vine de la home");
//console.log(state);
//console.log(state?.users);
  const plans = planContents.map((obj, i) => {
    return (
      <Plan
        key={obj.header}
        header={obj.header}
        price={obj.price}
        features={obj.features}
        buttonLabel={obj.buttonLabel}
		link={obj.link}
		cul={obj.cul}
        outline={obj.outline}
      />
    );
  });

  return (
	            <div className="container">
            <div className="row"> 
    
      {plans}

		</div>
			    <p className="lead">
Hai la treaba!
    </p>
		</div>

  );
};

//export default Plans;
