import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import AuthLayout from "../hoc/authLayout";
import { toast } from 'react-toastify';

import { accountService, alertService } from '../_services';

function ResetPassword({  }) {
    const TokenStatus = {
        Validating: 'Validating',
        Valid: 'Valid',
        Invalid: 'Invalid'
    }
     const location = useLocation();
     const history = useNavigate();

    const [token, setToken] = useState(null);
    const [tokenStatus, setTokenStatus] = useState(TokenStatus.Validating);

    useEffect(() => {
        const { token } = queryString.parse(location.search);
////window.alert(token);
        // remove token from url to prevent http referer leakage
	//console.log(location.pathname);
      //  history.replace(location.pathname);

        accountService.validateResetToken(token)
            .then(() => {
                setToken(token);
                setTokenStatus(TokenStatus.Valid);
            })
            .catch((error) => {
                setTokenStatus(TokenStatus.Invalid);
		toast.error(error);
            });
    }, []);

    function getForm() {
        const initialValues = {
            password: '',
            confirmPassword: ''
        };

        const validationSchema = Yup.object().shape({
            password: Yup.string()
                .min(6, 'Password must be at least 6 characters')
                .required('Password is required'),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Parolele trebuie să se potrtivească')
                .required('Confirm Password is required'),
        });

        function onSubmit({ password, confirmPassword }, { setSubmitting }) {
            alertService.clear();
            accountService.resetPassword({ token, password, confirmPassword })
                .then(() => {
                    alertService.success('Resetarea parolei cu succes, acum vă puteți autentifica', { keepAfterRouteChange: true });
		    toast.success('Resetarea parolei cu succes, acum vă puteți autentifica');
                    history('/login');
                })
                .catch(error => {
                    setSubmitting(false);
                    toast.error(error);
                });
        }

        return (
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                {({ errors, touched, isSubmitting }) => (
                    <Form>
                        <div className="form-group">
                            <label>Parola</label>
                            <Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                            <ErrorMessage name="password" component="div" className="invalid-feedback" />
                        </div>
                        <div className="form-group">
                            <label>Confirmă parola</label>
                            <Field name="confirmPassword" type="password" className={'form-control' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')} />
                            <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback" />
                        </div>
                        <div className="form-row">
                            <div className="form-group col">
                                <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    Reseteaza parola
                                </button>
                                <Link to="login" className="btn btn-link">Abandon</Link>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }

    function getBody() {
        switch (tokenStatus) {
            case TokenStatus.Valid:
                return getForm();
            case TokenStatus.Invalid:
                return <div>Validarea Tokenului a eșuat, dacă Tokenul a expirat, puteți obține unul nou la pagina <Link to="/forgot-password">Aţi uitat parola</Link> .</div>;
            case TokenStatus.Validating:
                return <div>Validare token...</div>;
        }
    }

    return (
     <AuthLayout>
        <div>
            <h3 className="card-header">Reseteaza parola</h3>
            <div className="card-body">{getBody()}</div>
        </div>
    </AuthLayout>
    )
}

export { ResetPassword }; 